<template>
    <grid-view 
        title="Поурочное планирование"
        filter
        create
        create-title="Создать запись"
        url-create="/lesson-plan/create"
        url-update="/lesson-plan/update/:id"
        store-module="lesson_plan"
        :headers="[
            { text: 'ID', value: 'id', width: 1 },
            { text: 'Год', value: 'year', width: 1, sortable: false },
            { text: 'Предмет', value: 'subject', width: 1 },
            { text: 'Класс', value: 'grade', width: 1 },
            { text: 'Номер урока', value: 'number', width: 1 },
            { text: 'Тема', value: 'theme', width: 1 },
            { text: '', value: 'edit', width: 1, sortable: false },
            { text: '', value: 'remove', width: 1, sortable: false }
        ]"
        :format="formatItems"
        :data-table-options="{
            'show-select': true,
        }"
    >
        <!-- Filter -->
        <template v-slot:filter="{ filter }">
            
            <v-progress-circular
                v-if="loading.initial"
                :size="50"
                :color="$const.color.primary"
                indeterminate
                class="d-flex mx-auto"
            ></v-progress-circular>
            <v-row v-else>
                <v-col lg="6" sm="12">
                    <v-text-field 
                        v-model="filter.year"
                        label="Календарный год" 
                        type="text" 
                        placeholder=" "
                        :color="$const.color.primary" 
                    ></v-text-field>
                </v-col>

                <v-col lg="6" sm="12">
                    <v-select
                        v-model="filter.subject"
                        required
                        :items="subjects"
                        label="Предмет"
                    />
                </v-col>

                <v-col lg="6" sm="12">
                    <v-select
                        v-model="filter.grade"
                        :disabled="!filter.subject"
                        required
                        :items="getGrades(filter.subject)"
                        label="Класс"
                    />
                </v-col>
                

                <v-col lg="6" sm="12">
                    <v-text-field 
                        v-model="filter.number"
                        label="Номер урока" 
                        type="text" 
                        placeholder=" "
                        :color="$const.color.primary" 
                    ></v-text-field>
                </v-col>


                <v-col lg="6" sm="12">
                    <v-text-field 
                        v-model="filter.theme"
                        label="Тема" 
                        type="text" 
                        placeholder=" "
                        :color="$const.color.primary" 
                    ></v-text-field>
                </v-col>
            </v-row>
        </template>
        <!-- END Filter -->
    </grid-view>
</template>
<script>
import GridView from '@/components/crud/GridView.vue'

export default {
    name: 'LessonPlanIndex',
    components: { GridView }, 
    data () {
        return {
            loading: {
                initial: false
            }
        }
    },
    computed: {
        subjects () {
            return this.$store.state.app.subjects
        }
    },
    async created () {
        this.loading.initial = true
        try {
            await this.$store.dispatch('app/waitUntilRequiredDataLoaded')
        } catch (e) {
            console.error(e)
        } finally {
            this.loading.initial = false
        }
    },
    methods: {
        getGrades (subject) {
            if (!subject) { return [] }
            return this.subjects.find(item => item.value === subject)?.grades.flat()
        },
        formatItems(items) {
            return items.map(item => {

                if (item.number === 0)
                    item.number = "Вводный урок"

                if (item.number === -1)
                    item.number = "Тематическое и поурочное планирование"

                return item
            })
        } 
    }
}
</script>